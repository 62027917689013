const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchPicture = (uuid, chipId, selectedSide, selectedType, device) => {
    let url = `${backendURL}/control/zambeziCam/picture`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            selectedType,
            selectedSide,
            device,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchPicture};

const fetchSchedule = (uuid, device, chipId, action, days, hours, minutes, seconds, selectedSide, selectedType, flag) => {
    let url = `${backendURL}/control/zambeziCam/schedule`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            action,
            device,
            days, 
            hours, 
            minutes, 
            seconds,
            selectedSide, 
            selectedType,
            flag
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchSchedule};