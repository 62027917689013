import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalStateProvider } from './contexts/GlobalStateContext';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomePage from './containers/HomePage';
import ViewPage from './containers/ViewPage';
import ControlPage from './containers/ControlPage';
import EphysPage from './containers/EphysPage';

const App = () => {
	return (
		<Router>
			<GlobalStateProvider>
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route path='/view' element={<ViewPage />} />
					<Route path='/control' element={<ControlPage />} />
					<Route path='/ephys' element={<EphysPage />} />
				</Routes>
			</GlobalStateProvider>
		</Router>
	);
};
		
export default App;