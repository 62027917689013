import Layout from '../components/Layout';
import '../styles/main.css';
import { useGlobalState } from '../contexts/GlobalStateContext';
import { useState } from 'react';

const HomePage = () => {
    const { uuid, setUUID } = useGlobalState();
    const [timestamp, setTimestamp] = useState('');
    const [maxwellChipId, setMaxwellChipId] = useState('');
    const [purpose, setPurpose] = useState('');
    const [sampleType, setSampleType] = useState('');
    const [aggregationDate, setAggregationDate] = useState('');
    const [platingDate, setPlatingDate] = useState('');
    const [species, setSpecies] = useState('');
    const [cellLine, setCellLine] = useState('');
    const [genotype, setGenotype] = useState('');
    const [cultureMedia, setCultureMedia] = useState('');
    const [organoidTrackerSrNo, setOrganoidTrackerSrNo] = useState('');
    const [organoidModification, setOrganoidModification] = useState('');
    const [organoidModificationDate, setOrganoidModificationDate] = useState('');
    const [comments, setComments] = useState('');

    // Add more state hooks for the rest of the fields as needed

    return (
        <Layout title='Initialization' content='Initialization'>
            <div className='txt-body'>
                <h1>Initialization Page</h1>
                <hr />
            </div>
            <div className='txt-body'>
				<label>
					UUID:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={uuid}
						onChange={(e) => setUUID(e.target.value)}
						placeholder='Enter uuid'
					/>
				</label>
				<label>
					Timestamp:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={timestamp}
						onChange={(e) => setTimestamp(e.target.value)}
						placeholder='Enter Timestamp'
					/>
				</label>
				<label>
					Maxwell Chip ID:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={maxwellChipId}
						onChange={(e) => setMaxwellChipId(e.target.value)}
						placeholder='Enter Maxwell Chip ID'
					/>
				</label>
				<label>
					Purpose:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={purpose}
						onChange={(e) => setPurpose(e.target.value)}
						placeholder='Enter Purpose'
					/>
				</label>
				<label>
					Sample Type:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={sampleType}
						onChange={(e) => setSampleType(e.target.value)}
						placeholder='Enter Sample Type'
					/>
				</label>
				<label>
					Aggregation Date:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={aggregationDate}
						onChange={(e) => setAggregationDate(e.target.value)}
						placeholder='Enter Aggregation Date'
					/>
				</label>
				<label>
					Plating Date:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={platingDate}
						onChange={(e) => setPlatingDate(e.target.value)}
						placeholder='Enter Plating Date'
					/>
				</label>
				<label>
					Species:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={species}
						onChange={(e) => setSpecies(e.target.value)}
						placeholder='Enter Species'
					/>
				</label>
				<label>
					Cell Line:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={cellLine}
						onChange={(e) => setCellLine(e.target.value)}
						placeholder='Enter Cell Line'
					/>
				</label>
				<label>
					Genotype:
					<input
						type='text'
						className="form-control form-control-wide m-1"
						value={genotype}
						onChange={(e) => setGenotype(e.target.value)}
						placeholder='Enter Genotype'
					/>
				</label>
				<label>
				Culture Media:
				<input
					type="text"
					className="form-control form-control-wide m-1"
					value={cultureMedia}
					onChange={(e) => setCultureMedia(e.target.value)}
					placeholder="Enter Culture Media"
				/>
				</label>
				<label>
					Organoid Tracker Serial Number:
					<input
						type="text"
						className="form-control form-control-wide m-1"
						value={organoidTrackerSrNo}
						onChange={(e) => setOrganoidTrackerSrNo(e.target.value)}
						placeholder="Enter Organoid Tracker Serial Number"
					/>
				</label>
				<label>
					Organoid Modification:
					<input
						type="text"
						className="form-control form-control-wide m-1"
						value={organoidModification}
						onChange={(e) => setOrganoidModification(e.target.value)}
						placeholder="Enter Organoid Modification"
					/>
				</label>
				<label>
					Organoid Modification Date:
					<input
						type="text"
						className="form-control form-control-wide m-1"
						value={organoidModificationDate}
						onChange={(e) => setOrganoidModificationDate(e.target.value)}
						placeholder="Enter Organoid Modification Date"
					/>
				</label>
				<label>
					Comments:
					<textarea
						className="form-control form-control-wide m-1"
						value={comments}
						onChange={(e) => setComments(e.target.value)}
						placeholder="Enter Comments"
					/>
				</label>
			</div> 
			<div>
				<button className='btn btn-secondary mt-2' >Submit</button>

			</div>
				<>
				""
				</>
        </Layout>
    );
};

export default HomePage;