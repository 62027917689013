import React, { useState } from 'react';

const ZambeziStart = ({ deviceName, displayName, state }) => {
    return (
        <div style={{ width: '50%' }}>
            <div>
                <h1>State</h1>
            </div>
            <div className="stateHistoryContainer">
            <pre>{JSON.stringify(state, null, 2)}</pre>
            </div>
        </div>
    );
};

const NileStart = ({ deviceName, displayName, state }) => {
    return (
        <div style={{ width: '50%' }}>
            <div>
                <h1>State</h1>
            </div>
            <div className="stateHistoryContainer">
            <pre>{JSON.stringify(state, null, 2)}</pre>
            </div>
        </div>
    );
};

const ZambeziCamStart = ({ deviceName, state}) => {

    return (
        <div style={{ width: '50%' }}>
            <div>
                <h1>State</h1>
            </div>
            <div className="stateHistoryContainer">
                <pre>{JSON.stringify(state, null, 2)}</pre>
            </div>
        </div>
    );
};

const RioCamStart = ({ deviceName, state}) => {

    return (
        <div style={{ width: '50%' }}>
            <div>
                <h1>State</h1>
            </div>
            <div className="stateHistoryContainer">
                <pre>{JSON.stringify(state, null, 2)}</pre>
            </div>
        </div>
    );
};

const DorothyStart = ({ deviceName, state }) => (
    <div style={{ width: '50%' }}>
		<div>
            <h1>State</h1>
		</div>
        <div className="stateHistoryContainer">
            <pre>{JSON.stringify(state, null, 2)}</pre>
        </div>
    </div>
);

const DinolitesStart = ({ deviceName, state }) => (
    <div style={{ width: '50%' }}>
		<div>
            <h1>State</h1>
		</div>
        <div className="stateHistoryContainer">
            <pre>{JSON.stringify(state, null, 2)}</pre>
        </div>
    </div>
);


export {ZambeziStart, ZambeziCamStart, RioCamStart, DorothyStart, DinolitesStart, NileStart}