import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import { fetchPair, fetchSchedule } from '../../utils/fetchDinolite';
import {ScheduleLayout} from '../Devices'

const ScheduleModal = ({ showScheduleModal, setShowScheduleModal, device, chipId }) => {
    const [days, setDays] = useState('0');
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');
    const [seconds, setSeconds] = useState('0');
    const [flag, setFlag] = useState(false);
  
    const { uuid } = useGlobalState();
  
    const action = "picture";
  
    const handleSubmit = () => {
      fetchSchedule(uuid, device, chipId, action, days, hours, minutes, seconds, flag)
      setShowScheduleModal(false);
    };
  
    const handleClose = () => setShowScheduleModal(false);

    return (
      <div>
        {showScheduleModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Take a picture every:</h2>
              <ScheduleLayout
                            days={days} setDays={setDays}
                            hours={hours} setHours={setHours}
                            minutes={minutes} setMinutes={setMinutes}
                            seconds={seconds} setSeconds={setSeconds}
                            flag={flag} setFlag={setFlag}
                            handleSubmit={handleSubmit}
                            handleClose={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export { ScheduleModal };

const PairModal = ({ showPairModal, setShowPairModal, device, chipId, duration }) => {

  const { uuid } = useGlobalState();

  const handleClose = () => setShowPairModal(false);

  const [index, setIndex] = useState('1');
  const [chip, setChip] = useState('12345');

  const handleIndexChange = (e) => {
    setIndex(e.target.value);
  };

  const handleChipChange = (e) => {
    setChip(e.target.value);
  };

  const handleSubmit = () => {
    fetchPair(uuid, device, chipId, chip, index)
    setShowPairModal(false);
  };


  return (
    <div>
      {showPairModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Add a pair:</h2>
            <div>
							<label>Chip:</label>
							<input value={chip} onChange={handleChipChange} />
						</div>
						<div>
							<label>Index:</label>
							<input value={index} onChange={handleIndexChange} />
						</div>
            <div>
            <button className='btn btn-secondary mt-2' onClick={handleSubmit}>Add</button>
            <button className='btn btn-secondary mt-2' onClick={handleClose}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { PairModal }