const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchRequest = (uuid, device, chipId, volume, action) => {
    let url = `${backendURL}/control/${device}/sendAction`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
            volume, 
            action,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchRequest};

const fetchConfig = (uuid, device, chipId, config) => {
    let url = `${backendURL}/control/${device}/config`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
            config
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchConfig};

const fetchSchedule = (uuid, device, chipId, days, hours, minutes, seconds, flag, action, volume) => {
    let url = `${backendURL}/control/${device}/schedule`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
            days,
            hours,
            minutes,
            seconds,
            flag,
            action,
            volume
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchSchedule};

const fetchServoRequest = (uuid, device, chipId, volume, dispenseState, aspirateState) => {
    let url = `${backendURL}/control/${device}/servo_request`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
            volume, 
            dispenseState,
            aspirateState,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchServoRequest};


const fetchPurgeRequest = (uuid, device, aspirateStatePurge, dispenseStatePurge) => {
    let url = `${backendURL}/control/${device}/purge_request`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            device,
            aspirateStatePurge,
            dispenseStatePurge,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchPurgeRequest};
