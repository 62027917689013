const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchState = (uuid, action, device, seconds) => {
    let url = `${backendURL}/control/sendAction`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            action,
            device,
            uuid,
			seconds,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchState};