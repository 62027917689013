import React, { useState } from 'react';
import { useGlobalState } from '../contexts/GlobalStateContext';
import {fetchState} from '../utils/fetchDeviceState'
import { fetchSchedule } from '../utils/fetchZambeziCam';
import { fetchScheduleRemoval } from '../utils/fetchData';

const DefaultCmds = ({device, SelectedTime}) => {
    const { uuid } = useGlobalState();
    const [selectedTime, setSelectedTime] = useState('');
    return (
        <>
        <div className='button-group'>
            <button 
                className='btn btn-secondary mt-2'
                onClick={() => {
                    fetchState(uuid, 'start', device, SelectedTime);
                }}>Start
            </button>
            <button 
                className='btn btn-secondary mt-2'
                onClick={() => {
                    fetchState(uuid, 'end', device, SelectedTime);
                }}>End
            </button>
            <button 
                className='btn btn-secondary mt-2'
                onClick={() => {
                    fetchState(uuid, 'resume', device, SelectedTime);
                }}>Resume
            </button>
            <button 
                className='btn btn-secondary mt-2' 
                onClick={() => {
                    fetchState(uuid, 'ping', device, SelectedTime);
                }}>Ping
            </button>
            </div>
        <div className='button-group mt-2'>
            <button 
                className='btn btn-secondary'
                onClick={(e) => {
                    e.preventDefault();
                    fetchState(uuid, 'pause', device, selectedTime);
                }}>
                Pause for:
                <input 
                    type="text"
                    className="time-input"
                    placeholder='Time in sec.'
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                />
            </button>
        </div>
        </>
        );
    }

export {DefaultCmds}

const ScheduleLayout = ({ days, setDays, hours, setHours, minutes, setMinutes, seconds, setSeconds, flag, setFlag, handleSubmit, handleClose }) => (
    <>
    <div className="time-inputs-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="time-input" style={{ margin: '0 10px', textAlign: 'center' }}>
            <div>Day</div>
            <input 
            type="number"
            className='form-control'
            placeholder="0" 
            value={days}
            onChange={(e) => setDays(e.target.value)}
            />
        </div>
        <div className="time-input" style={{ margin: '0 10px', textAlign: 'center' }}>
            <div>Hour</div>
            <input 
            type="number"
            className='form-control'
            placeholder="0" 
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            />
        </div>
        <div className="time-input" style={{ margin: '0 10px', textAlign: 'center' }}>
            <div>Min</div>
            <input 
            type="number"
            className='form-control'
            placeholder="0" 
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            />
        </div>
        <div className="time-input" style={{ margin: '0 10px', textAlign: 'center' }}>
            <div>Sec</div>
            <input 
            type="number"
            className='form-control'
            placeholder="0" 
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
            />
        </div>
    </div>
        <div style={{ marginTop: '20px', marginBottom: '10px', textAlign: 'left' }}>
            <input 
                type="checkbox"
                checked={flag}
                onChange={(e) => setFlag(e.target.checked)}
                style={{ marginRight: '10px' }}
            />
            <label>Flag</label>
        </div>
        <div className='button-group'>
            <button className='btn btn-secondary mt-2' onClick={handleSubmit}>Confirm</button>
            <button className='btn btn-secondary mt-2' onClick={handleClose}>Close</button>
        </div>
   
    </>
);

export { ScheduleLayout }


const ConfigScheduleLayout = ({ device, state, showScheduleConfigModal, setShowScheduleConfigModal }) => {

    const { uuid } = useGlobalState();
    const [selectedIndices, setSelectedIndices] = useState([]);

    const handleClose = () => setShowScheduleConfigModal(false);

    const handleSubmit = () => {
        fetchScheduleRemoval(uuid, device, selectedIndices)
        setShowScheduleConfigModal(false);
    };

    const handleCheckboxChange = (index) => {
        if (selectedIndices.includes(index)) {
            setSelectedIndices(selectedIndices.filter(i => i !== index));
        } else {
            setSelectedIndices([...selectedIndices, index]);
        }
    };
    const getTagFromScheduleItem = (scheduleItem) => {
        // Extract tag from the schedule item string
        const tagMatch = scheduleItem.match(/tag: (\d+)/);
        return tagMatch ? tagMatch[1] : null;
    };

    return (
        <>
            {showScheduleConfigModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Schedule removal for {device}</h2>
                        <div>
                            {state.SCHEDULE.length > 0 ? (
                                <ul>
                                    {state.SCHEDULE.map((scheduleItem) => {
                                        const tag = getTagFromScheduleItem(scheduleItem);
                                        return (
                                            <li key={tag}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${tag}`}
                                                    name={`checkbox-${tag}`}
                                                    onChange={() => handleCheckboxChange(tag)}
                                                    checked={selectedIndices.includes(tag)}
                                                />
                                                <label htmlFor={`checkbox-${tag}`}>{JSON.stringify(scheduleItem)}</label>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <p>No tasks scheduled.</p>
                            )}
                        </div>
                        <div className='button-group'>
                            <button className='btn btn-secondary mt-2' onClick={handleSubmit}>Confirm</button>
                            <button className='btn btn-secondary mt-2' onClick={handleClose}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export { ConfigScheduleLayout };