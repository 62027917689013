import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import { fetchSchedule } from '../../utils/fetchNile';
import {ScheduleLayout} from '../Devices'

const ScheduleModal = ({ showScheduleModal, setShowScheduleModal, device, chipId }) => {
    const [days, setDays] = useState('0');
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');
    const [seconds, setSeconds] = useState('0');
    const [flag, setFlag] = useState(false);

    const [action, setAction] = useState("");
    const [volume, setVolume] = useState("");
  
    const { uuid } = useGlobalState();
  
    const handleSubmit = () => {
      fetchSchedule(uuid, device, chipId, days, hours, minutes, seconds, flag, action, volume)
      setShowScheduleModal(false);
    };

    const handleClose = () => setShowScheduleModal(false);
  
    return (
      <div>
          {showScheduleModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Take a picture every:</h2>
              <div className="action-inputs-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                <div className="action-input" style={{ margin: '0 10px', textAlign: 'center' }}>
                  <input 
                    type="checkbox"
                    checked={action === 'dispense'}
                    onChange={() => setAction('dispense')}
                  />
                  <label>Dispense</label>
                </div>
                <div className="action-input" style={{ margin: '0 10px', textAlign: 'center' }}>
                  <input 
                    type="checkbox"
                    checked={action === 'aspirate'}
                    onChange={() => setAction('aspirate')}
                  />
                  <label>Aspirate</label>
                </div>
                <div className="action-input" style={{ margin: '0 10px', textAlign: 'center' }}>
                  <input 
                    type="checkbox"
                    checked={action === 'feed'}
                    onChange={() => setAction('feed')}
                  />
                  <label>Feed</label>
                </div>
              </div>
              <label>Volume (uL):</label>
              <input
                type="number"
                className='form-control'
                placeholder="Volume" 
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
              />
  
              <ScheduleLayout
                            days={days} setDays={setDays}
                            hours={hours} setHours={setHours}
                            minutes={minutes} setMinutes={setMinutes}
                            seconds={seconds} setSeconds={setSeconds}
                            flag={flag} setFlag={setFlag}
                            handleSubmit={handleSubmit}
                            handleClose={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export { ScheduleModal };