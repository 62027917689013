import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import { ScheduleModal } from '../RioCam/RioCamSchedule';
import { DefaultCmds, ConfigScheduleLayout } from '../Devices';
import { fetchPicture, fetchLoadImages } from '../../utils/fetchRioCam'
import {  CheckBoxGridCam, handleCheckboxChangeCam } from '../CheckboxMatrix';

const RioCam = ({deviceName, displayName, state}) => {
	    
    // schedule related
	const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showScheduleConfigModal, setShowScheduleConfigModal] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const device = deviceName;
	const seconds = ''
	const { uuid } = useGlobalState();

    const [chipId, setChipId] = useState('');

    const handleCheckboxChange = (e, rowIndex, columnIndex) => {
        handleCheckboxChangeCam(e, rowIndex, columnIndex, setSelectedCheckboxes);
      };

  return (
        <div style={{ width: '50%' }}>
            <h1>{displayName}    </h1>
            <CheckBoxGridCam 
                selectedValue={selectedCheckboxes}
                onCheckboxChange={handleCheckboxChange}
            />          
            <DefaultCmds
                device={device}
                SelectedTime={seconds}
            />
            <div className='button-group'>
                {/* <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleModal(true)}>Schedule</button> */}
                <button 
                    className='btn btn-secondary mt-2'
                    onClick={() => fetchPicture(uuid, selectedCheckboxes, device)}>
                    Picture
                </button>
                <button 
                    className='btn btn-secondary mt-2'
                    onClick={() => fetchLoadImages(uuid, chipId, device)}>
                    Load images
                </button>
            </div>
            <ScheduleModal 
                showScheduleModal={showScheduleModal}
                setShowScheduleModal={setShowScheduleModal}
                device={device}
                chipId={chipId}
            />
            <ConfigScheduleLayout
                    showScheduleConfigModal={showScheduleConfigModal}
                    setShowScheduleConfigModal={setShowScheduleConfigModal}
                    device = {device}
                    state = {state}
            ></ConfigScheduleLayout>
        </div>
    );
};


export {RioCam}