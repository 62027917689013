const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchPicture = (uuid, device, chipId) => {
    let url = `${backendURL}/control/dinolite/picture`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            device,
            chipId
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchPicture};

const fetchSchedule = (uuid, device, chipId, action, days, hours, minutes, seconds, flag) => {
    let url = `${backendURL}/control/dinolite/schedule`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            action,
            device,
            days, 
            hours, 
            minutes, 
            seconds, 
            flag
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchSchedule};

const fetchPair = (uuid, device, chipId, chip, index) => {
    let url = `${backendURL}/control/dinolite/add_pair`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
            chip, 
            index
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchPair};