import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import { ScheduleModal } from '../ZambeziCam/ZambeziCamSchedule';
import { DefaultCmds, ConfigScheduleLayout } from '../Devices';
import { fetchPicture} from '../../utils/fetchZambeziCam'

const ZambeziCam = ({deviceName, displayName, state}) => {
    const [phChecked, setPhChecked] = useState(false);
    const [volumeChecked, setVolumeChecked] = useState(false);

    const [selectedSide, setSelectedSide] = useState('');
    const [selectedType, setSelectedType] = useState('');
	    
    // schedule related
	const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showScheduleConfigModal, setShowScheduleConfigModal] = useState(false);

	const device = deviceName;
	const seconds = ''
	const { uuid } = useGlobalState();

    const [chipId, setChipId] = useState('');

    const handlePhCheck = () => {
        setPhChecked(!phChecked);
        if (!phChecked) {
            setVolumeChecked(false);
            setSelectedType('ph');
        } else {
            setSelectedType('');
        }
    };

    const handleVolumeCheck = () => {
        setVolumeChecked(!volumeChecked);
        if (!volumeChecked) {
            setPhChecked(false);
            setSelectedType('volume');
        } else {
            setSelectedType('');
        }
    };

  return (
        <div style={{ width: '50%' }}>
            <h1>{displayName}    </h1>
            <div className='m-2'>
            <label>chip id: </label>
                <input 
                    className='m-2' 
                    type="text"
                    value={chipId}
                    onChange={(e) => setChipId(e.target.value)} 
                />
            </div>
            <input
                className='m-2'
                type="checkbox" 
                checked={phChecked} 
                onChange={handlePhCheck} 
            />
            <label>pH</label>
            <input
                className='m-2' 
                type="checkbox" 
                checked={volumeChecked} 
                onChange={handleVolumeCheck}
            />
            <label>Volume</label>
            <div>
                <input
                    className='m-2'
                    type="checkbox"
                    checked={selectedSide === 'right'}
                    onChange={() => setSelectedSide(selectedSide === 'right' ? '' : 'right')}
                />
                <label>Right</label>

                <input
                    className='m-2'
                    type="checkbox"
                    checked={selectedSide === 'left'}
                    onChange={() => setSelectedSide(selectedSide === 'left' ? '' : 'left')}
                />
                <label>Left</label>

                <input
                    className='m-2'
                    type="checkbox"
                    checked={selectedSide === 'both'}
                    onChange={() => setSelectedSide(selectedSide === 'both' ? '' : 'both')}
                />
                <label>Both</label>
            </div>
            <DefaultCmds
                device={device}
                SelectedTime={seconds}
            />
            <div className='button-group'>
                <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleModal(true)}>Schedule</button>
                <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleConfigModal(true)}>Config Schedule</button>
                <button 
                    className='btn btn-secondary mt-2'
                    onClick={() => fetchPicture(uuid, chipId, selectedSide, selectedType, device)}>
                    Picture
                </button>
            </div>
            <ScheduleModal 
                showScheduleModal={showScheduleModal}
                setShowScheduleModal={setShowScheduleModal}
                device={device}
                chipId={chipId}
                selectedSide={selectedSide} 
                selectedType={selectedType}
            />
            <ConfigScheduleLayout
                    showScheduleConfigModal={showScheduleConfigModal}
                    setShowScheduleConfigModal={setShowScheduleConfigModal}
                    device = {device}
                    state = {state}
            ></ConfigScheduleLayout>
        </div>
    );
};


export {ZambeziCam}