const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchRequest = (uuid, device, chipId, record, swap, duration, config) => {
    let url = `${backendURL}/control/dorothy/request`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
            record, 
            swap, 
            duration, 
            config
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchRequest};

const fetchSchedule = (uuid, device, chipId, action, days, hours, minutes, seconds, duration) => {
    let url = `${backendURL}/control/dorothy/schedule`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            action,
            device,
            days,
            hours,
            minutes,
            seconds,
            duration,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchSchedule};