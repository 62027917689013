import Layout from '../components/Layout';
import PlotComponent from '../components/PlotComponent';
import {fetchDataForDevice, fetchImageForDevice} from '../utils/fetchData'
import '../styles/main.css'
import React, { useState } from 'react';
import Tooltip from '../components/Tooltip';
import { useGlobalState } from '../contexts/GlobalStateContext';


export const SharedStateContext = React.createContext();

const ViewPage = () => {

	const [dataForDevice1, setDataForDevice1] = useState(null);
	const [dataForDevice2, setDataForDevice2] = useState(null);
	const { uuid, setUUID } = useGlobalState();
	const [isLoading, setIsLoading] = useState("");

	const [isActive, setIsActive] = useState(false);

	const uuidExamples = ['2023-09-16-efi-mouse-5plex-official',
							'2023-09-07-efi-mouse-2plex-official', 
							'2023-09-13-efi-2plex-testing',
							'2023-09-14-efi-2plex-testing']
	const [showSuggestions, setShowSuggestions] = useState(false);

	const handleToggle = () => {
		setIsActive(!isActive);
	  };

	const handleLoadData = () => {
		setIsLoading("Loading...");
		setDataForDevice1(null)
		setDataForDevice2(null)
		fetchDataForDevice(uuid, setDataForDevice1, setDataForDevice2, setIsLoading);
		//fetchDataForDevice(20174, uuid, setDataForDevice2, setIsLoading);
		setIsActive(false)
	};

	const handleInputChange = (e) => {
		setUUID(e.target.value);
		setShowSuggestions(false); // show suggestions when user types
	};
	
	const handleSuggestionClick = (example) => {
		console.log('Suggestion clicked:', example); // Debugging log
		setUUID(example); // Set the input value to the clicked example command
		setShowSuggestions(false); // Hide suggestions after user selects an example
	};

	return (
		<Layout title='View' content='View'>
		<div className='txt-body'>
			<h1>View Page</h1>
			<hr />
			<div>
				<input
					type="text"
					className="form-control form-control-wide"
					value={uuid}
					onChange={handleInputChange}
					placeholder="Enter uuid"
					onClick={() => setShowSuggestions(true)} // Show suggestions on double-click
					onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
				/>
					
				{showSuggestions && (
					<div>
					{uuidExamples.map((example, index) => (
						<div className="form-control form-control-wide" key={index} onClick={() => handleSuggestionClick(example)} style={{ cursor: 'pointer' }}>
						{example}
						</div>
					))}
					</div>
				)}
				{/* CHECK THIS ONCLICK!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! MSPM */}
				<button className='btn btn-secondary mt-2' onClick={handleLoadData}>Load Data</button>
				{/* <div>
					<button className='btn btn-secondary mt-2' onClick={handleButtonClick}>Load Image</button>
					{image && <img src={image} alt="From server" />}
				</div> */}
			</div>

			{!dataForDevice1 || !dataForDevice2 ? (
			<div>
				<p>{isLoading}</p>
			</div>
			) : (
				<div style={{ marginBottom: '2em' }}>

				<div className='txt-body mt-2'>
					<label className="switch mt-2">
					<input type="checkbox" checked={isActive} onChange={handleToggle} />
					<span className="slider"></span>
					</label>
					{isActive ? 'Disable images retrieval' : 'Enable images retrieval and double-click on any data point to view its corresponding image'}
				</div>
				<hr />
				<div className='tube mt-4'>
				<h3 className='tube-title'> {dataForDevice1.side} tube. Chip number: {dataForDevice1.id} </h3>
				<h3 className='tube-title'> {dataForDevice2.side} tube. Chip number: {dataForDevice2.id}</h3>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1em' }}>
					<div style={{ width: '48%', position: 'relative' }}>
						<PlotComponent 
						title='Expected vs Estimated Volume Collected '
						data={dataForDevice1} 
						var1='currentVol'
						name1='Tube Volume'
						var2='volPump'
						name2="Expected Volume"
						var3='volCompVision'
						name3='Estimated Volume (CV)'
						uuid={uuid}
						/>
						<Tooltip text={`Estimated Volume: The volume of media collected from the well, as determined by the computer vision algorithm.
						<br />
						Expected Volume: The volume of media that has been collected from the well, according to pump metrics.
						<br />
						Tube Volume: The current total volume of media present in the tube.`} />
					</div>
					<div style={{ width: '48%', position: 'relative' }}>
						<PlotComponent
						title='Expected vs Estimated Volume Collected'
						data={dataForDevice2} 
						var1='currentVol'
						name1='Tube Volume'
						var2='volPump'
						name2='Expected Volume'
						var3='volCompVision'
						name3="Estimated Volume (CV)"
						uuid={uuid}
						/>
						<Tooltip text={`Estimated Volume: The volume of media collected from the well, as determined by the computer vision algorithm.
						<br />
						Expected Volume: The volume of media that has been collected from the well, according to pump metrics.
						<br />
						Tube Volume: The current total volume of media present in the tube.`} />
					</div>
				</div>
				<hr />

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ width: '48%', position: 'relative' }}>
					<PlotComponent
						title='Expected minus estimated'
						data={dataForDevice1} 
						var1='pumpMinuscv'
						name1='Difference'
						uuid={uuid}
					/>
					<Tooltip text="Interpretation: Data points close to zero suggest good alignment between CV and pump metrics (ideal scenario). <br />Negative values indicate the pump collected LESS media than reported. <br />Positive values suggest the pump collected MORE media than indicated by its metrics." />

					</div>
					<div style={{ width: '48%', position: 'relative' }}>
					<PlotComponent
						title='Expected minus estimated'
						data={dataForDevice2} 
						var1='pumpMinuscv' 
						name1='Difference'
						uuid={uuid}
					/>
					<Tooltip text="Interpretation: Data points close to zero suggest good alignment between CV and pump metrics (ideal scenario). <br />Negative values indicate the pump collected LESS media than reported. <br />Positive values suggest the pump collected MORE media than indicated by its metrics." />

					</div>
				</div>
				<hr />

				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ width: '48%', position: 'relative' }}>
					<PlotComponent
						title='Collected volume according to CV and pump'
						data={dataForDevice1} 
						var1='diffCompVisionVol'
						name1='Computer Vision'
						var2='diffPumpVol' 
						name2='Pump'
						uuid={uuid}
					/>
					<Tooltip text="This graph shows the volume of media collected from the well according to the Computer Vision (CV) algorithm, as compared to the pump system. Ideally, the two should match." />
					</div>
					<div style={{ width: '48%', position: 'relative' }}>
					<PlotComponent
						title='Collected volume according to CV and pump'
						data={dataForDevice2} diffPumpVol
						var1='diffCompVisionVol' 
						name1='Computer vision'
						var2='diffPumpVol' 
						name2='Pump'
						uuid={uuid}
					/>
					<Tooltip text="This graph shows the volume of media collected from the well according to the Computer Vision (CV) algorithm, as compared to the pump system. Ideally, the two should match." />
					</div>
				</div>
				<hr />
			</div>
			)}
		</div>
	  	</Layout>
	);
};

export default ViewPage;