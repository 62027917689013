import React, { useState, useCallback } from 'react';
import Plot from 'react-plotly.js';
import {fetchImageForDevice} from '../utils/fetchData'

// getComputedStyle(document.documentElement).getPropertyValue('--lightPurple').trim();
// getComputedStyle(document.documentElement).getPropertyValue('--mediumPurple').trim();
// getComputedStyle(document.documentElement).getPropertyValue('--darkPurple').trim();

const PlotComponent = ({ title, data, var1, name1, var2, name2, var3, name3, colors, uuid}) => {

  const deviceId = data.id

  const [clickTimeout, setClickTimeout] = useState(null);
  //const [image, setImage] = useState(null);


  const plotData = [
    {
      x: data.time,
      y: data[var1],
      type: 'scatter',
      mode: 'lines+markers',
      //light purple
      marker: { color: colors ? colors[1] : '#eb92eb' },
      name: name1,
    },
  ];

  const loadImage = (timestamp) => {
		fetchImageForDevice(deviceId, uuid, data, timestamp)
		// .then(() => {
		// 	setSelectedTimestamp(null);
		//   });
	};
 
  const handlePlotlyClick = (data) => {
    console.log('first click on graph');
    if (!clickTimeout) {
      const newTimeout = setTimeout(() => {
        setClickTimeout(null); // Reset when the timeout is complete.
      }, 500); // Wait for 300 milliseconds to detect the second click.
  
      setClickTimeout(newTimeout);
    } else {
      clearTimeout(clickTimeout); // second click detected
      setClickTimeout(null);
  
      //if (data && data.points && data.points[0]) {
      const point = data.points[0];
      //setSelectedTimestamp(point.x);
      console.log('Retrieving image:', point.x);
      //}
      loadImage(data.points[0]);  // Call your loadImage function here
    }
  };

  if (var2) {
    plotData.push({
      x: data.time,
      y: data[var2],
      type: 'scatter',
      mode: 'lines+markers',
      //medium purple
      marker: { color: colors ? colors[0] : '#9c119c' },
      name: name2,
    });
  }

  if (var3) {
    plotData.push({
      x: data.time,
      y: data[var3],
      type: 'scatter',
      mode: 'lines+markers',
      //dark purple
      marker: { color: colors ? colors[2] : '#630763' },
      name: name3,
    });
  }

  return (
    <Plot
      onClick={handlePlotlyClick}
      data={plotData}
      layout={{
        title: title,
        legend: {
          x: 0,
          xanchor: 'auto',
          y: 1,
          yanchor: 'auto',
        },
        yaxis: {
          title: 'Volume (mL)', // Adding 'mL' unit to the x-axis
        },
      }}
    />
  );
};

export default PlotComponent;