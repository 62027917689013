const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchDataForDevice = async (uuid, setDataForDevice1, setDataForDevice2, setIsLoading) => {
	try {
  
	  // First, fetch the chip IDs
	  // const chipResponse = await fetch(`http://mxvisuals-backend.braingeneers.gi.ucsc.edu/visualization/getChipId?uuid=${uuid}`);
	  const chipResponse = await fetch(`${backendURL}/visualization/getChipId?uuid=${uuid}`);
	  
	  const chipData = await chipResponse.json();
  
	  if (chipData.success) {
		const [chipId1, chipId2] = chipData.data;
		console.log("IDS:", chipData, chipId1, chipId2);
  
		// Fetch data for the first chip
		await fetchDataForSingleDevice(chipId1, uuid, setDataForDevice1, setIsLoading);
  
		// Fetch data for the second chip
		await fetchDataForSingleDevice(chipId2, uuid, setDataForDevice2, setIsLoading);
	  } else {
		console.log("Error fetching chip IDs:", chipData.error);
		setIsLoading("Failed to load chip IDs. Check uuid.");
	  }
	} catch (error) {
	  console.error("There was a problem with the fetch:", error);
	  setIsLoading("There was a problem with the fetch.")
	}
  };
  
  const fetchDataForSingleDevice = async (chipId, uuid, setData, setIsLoading) => {
	try {
	  //const response = await fetch(`http://mxvisuals-backend.braingeneers.gi.ucsc.edu/visualization/getLogData?device_id=${chipId}&uuid=${uuid}`);
	  const response = await fetch(`${backendURL}/visualization/getLogData?device_id=${chipId}&uuid=${uuid}`);
	  const data = await response.json();
  
	  if (data.success) {
		console.log(`Received data for device ${chipId}:`, data.data);
  
		const processedData = {
			id: null,
			side: null,
			time: [],
			well: [],
			image_name: [],
			//numbers
			volPump: [],
			volCompVision: [],
			currentVol: [],
			resOffset: [],
			accumVol: [],
			pumpMinuscv: [],
			diffCompVisionVol: [],
			diffPumpVol: [],
		  };
  
		processedData.id = data.data[0][2]
		processedData.side = data.data[0][24]
		data.data.forEach((line) => {
			processedData.time.push(line[0]);
			processedData.well.push(line[2]);
			processedData.image_name.push(line[22]);
			// numbers:
			processedData.volPump.push(parseFloat((parseFloat(line[4]) / 1000).toFixed(3)));
			processedData.volCompVision.push(parseFloat((parseFloat(line[14]) / 1000).toFixed(3)));
			processedData.currentVol.push(parseFloat((parseFloat(line[8]) / 1000).toFixed(3)));
			processedData.resOffset.push(parseFloat((parseFloat(line[10]) / 1000).toFixed(3)));
			processedData.accumVol.push(parseFloat((parseFloat(line[12]) / 1000).toFixed(3)));
			processedData.pumpMinuscv.push(parseFloat((parseFloat(line[16]) / 1000).toFixed(3)));
		});
		  // what pump says that was aspirated and what the cv says
		for (let i = 1; i < processedData.volCompVision.length; i++) {
			let diff_vision = processedData.volCompVision[i] - processedData.volCompVision[i - 1];
			let diff_pump = processedData.volPump[i] - processedData.volPump[i - 1];
			processedData.diffCompVisionVol.push(diff_vision);
			processedData.diffPumpVol.push(diff_pump);
		}
  
		setData(processedData);
		setIsLoading("Success!")
	  } else {
		console.log("Error:", data.error);
		setIsLoading("Failed to load data. Check uuid.");
	  }
	} catch (error) {
	  console.error("There was a problem with the fetch:", error);
	  setIsLoading("There was a problem with the fetch.")
	}
  };
  

const fetchImageForDevice = async (deviceId, uuid, data, timestamp) => {
	const index = data.time.indexOf(timestamp.x);
	console.log({timestamp})
	console.log({index})
	
	if (index !== -1) {
		// Grab the corresponding image name using the index
		const imageName = data.image_name[index];
		console.log(`Image name for device ${deviceId} at timestamp ${uuid}: ${imageName}`);
		try {
			const response = await fetch(`${backendURL}/visualization/getImage?device_id=${deviceId}&uuid=${uuid}&image_name=${imageName}`);
			//const response = await fetch(`http://mxvisuals-backend.braingeneers.gi.ucsc.edu/visualization/getImage?device_id=${deviceId}&uuid=${uuid}&image_name=${imageName}`);
			
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);
			//setImage(url);
			const newWindow = window.open("", "_blank");

			if (newWindow) {
				newWindow.document.title = imageName;
				newWindow.document.body.innerHTML = `
					<a href="${url}" download="${imageName}">
						<img id="${imageName}" src="${url}" alt="${imageName}" />
					</a>`;
			}

		} catch (error) {
			console.error('Fetch error:', error);
		}
	}
};
export {fetchDataForDevice, fetchImageForDevice};

const fetchScheduleRemoval = (uuid, device, selectedIndices) => {
    let url = `${backendURL}/control/scheduleRemoval`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            device,
			selectedIndices
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchScheduleRemoval};