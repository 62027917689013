import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import {fetchPicture} from '../../utils/fetchDinolite'
import { ScheduleModal, PairModal } from '../Dinolites/DinolitesSchedule';
import { DefaultCmds, ConfigScheduleLayout } from '../Devices';


const Dinolites = ({deviceName, displayName, state}) => {

    const { uuid } = useGlobalState();
    const [chipId, setChipId] = useState('');

    // schedule related
	const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showScheduleConfigModal, setShowScheduleConfigModal] = useState(false);
    const [showPairModal, setShowPairModal] = useState(false);

    const device = deviceName;
    const seconds = ''

    return (
        <div style={{ width: '50%' }}>
        <div>
            <h1>{displayName}</h1>
        </div>

        <div className='m-2'>
            <label>chip id: </label>
            <input 
                className='m-2' 
                type="text"
                value={chipId}
                onChange={(e) => setChipId(e.target.value)} 
            />
        </div>
        <DefaultCmds
            device = {device}
            SelectedTime = {seconds}
        />

        <div className='button-group'>
            <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleModal(true)}>Schedule</button>
            <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleConfigModal(true)}>Config Schedule</button>
            <button className='btn btn-secondary mt-2' onClick={() => setShowPairModal(true)}>Add pair</button>

            <button 
                className='btn btn-secondary mt-2'
                onClick={() => {
                    fetchPicture(uuid, device, chipId);
                }}>Picture
            </button>
            {/* <button className='btn btn-secondary mt-2' >Add</button>
            <button className='btn btn-secondary mt-2' >Remove</button> */}
        </div>
        <ScheduleModal 
            showScheduleModal={showScheduleModal}
            setShowScheduleModal={setShowScheduleModal}
            device = {device}
            chipId = {chipId}
        />
        <ConfigScheduleLayout
			    showScheduleConfigModal={showScheduleConfigModal}
				setShowScheduleConfigModal={setShowScheduleConfigModal}
				device = {device}
				state = {state}
		></ConfigScheduleLayout>
        <PairModal 
            showPairModal={showPairModal}
            setShowPairModal={setShowPairModal}
            device = {device}
            chipId = {chipId}
        />
    </div>
);
};

export {Dinolites}