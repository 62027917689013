import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({ text }) => {
  const [showInfo, setShowInfo] = useState(false);

  const tooltipStyle = {
    position: 'absolute',
    top: '60px',
    left: '0',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '3px',
    fontSize: '15px',
    zIndex: 1000
  };

  const triggerStyle = {
    position: 'absolute',
    left: 50,
    top: 30,
    cursor: 'pointer'
  };

  return (
    <>
      <span
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
        style={triggerStyle}
      >
        ℹ️
      </span>
      {showInfo && (
        <div style={tooltipStyle}>
          {text.split('<br />').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < text.split('<br />').length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired
};

export default Tooltip;
