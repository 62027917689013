import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import { fetchRequest } from '../../utils/fetchDorothy';
import { ScheduleModal } from '../Dorothy/DorothySchedule';
import { DefaultCmds, ConfigScheduleLayout } from '../Devices';

const Dorothy = ({deviceName, displayName, state}) => {

    const { uuid } = useGlobalState();

    const [record, setRecord] = useState(false);
    const [swap, setSwap] = useState(false);
    const [chipId, setChipId] = useState('');
    const [duration, setDuration] = useState('');
    const [config, setConfig] = useState('');

    const device = deviceName;
    const seconds = '';

    // schedule related
	const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showScheduleConfigModal, setShowScheduleConfigModal] = useState(false);

    const handleRecordChange = () => {
        setRecord(!record);
        if (!record) {
            setSwap(false);
        }
    };

    const handleSwapChange = () => {
        setSwap(!swap);
        if (!swap) {
            setRecord(false);
        }
    };

    return (
        <div style={{ width: '50%' }}>
            <h1>{displayName}</h1>
            <div>
                <input
                    className='m-2'
                    type="checkbox"
                    checked={record}
                    onChange={handleRecordChange}
                />
                <label>Record</label>
                <input
                    className='m-2'
                    type="checkbox"
                    checked={swap}
                    onChange={handleSwapChange}
                />
                <label>Swap</label>
            </div>
            <div className='m-2'>
                <label>chip id: </label>
                <input 
                    className='m-2' 
                    type="text"
                    value={chipId}
                    onChange={(e) => setChipId(e.target.value)} 
                />
                {record && (
                    <div>
                        <label>duration: </label>
                        <input 
                            className='m-2' 
                            type="text" 
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)} 
                        />
                    </div>
                )}
                {swap && (
                    <div>
                        <label>config: </label>
                        <input 
                            className='m-2' 
                            type="text"
                            value={config}
                            onChange={(e) => setConfig(e.target.value)} 
                        />
                    </div>
                )}
            </div>
            <DefaultCmds
                device={device}
                SelectedTime={seconds}
            />
            <div className='button-group'>
                <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleModal(true)}>Schedule</button>
                <button className='btn btn-secondary mt-2' onClick={() => setShowScheduleConfigModal(true)}>Config Schedule</button>
                <button 
                    className='btn btn-secondary mt-2'
                    onClick={() => {
                        fetchRequest(uuid, device, chipId, record, swap, duration, config);}}>
                        Go
                </button>
                <button 
                    className='btn btn-secondary mt-2' 
                    onClick={() => {
                        fetchState(uuid, 'stop', device, seconds);
                    }}>Stop
                </button>
            </div>
            <ScheduleModal 
                showScheduleModal={showScheduleModal}
                setShowScheduleModal={setShowScheduleModal}
                device = {device}
                chipId = {chipId}
                duration = {duration}
            />
            <ConfigScheduleLayout
                    showScheduleConfigModal={showScheduleConfigModal}
                    setShowScheduleConfigModal={setShowScheduleConfigModal}
                    device = {device}
                    state = {state}
            ></ConfigScheduleLayout>
        </div>
    );
};

export { Dorothy };