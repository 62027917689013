const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchDataEphys = async () => {
    const apiUrl = `${backendURL}/ephys/plot_graph`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        //console.log("Response from server:", data);

        if (data.success) {
            // Display data in log
            console.log("arroch:", data);
            // console.log("Neuron attributes:", data.data.neuron_attributes);
            // console.log("Neuron data:", data.data.neuron_data);
            // console.log("Raw time:", data.data.raw_time);

            return data; // Return the fetched data
        } else {
            throw new Error(`Server error: ${data.error}`);
        }

    } catch (error) {
        console.error("Fetch error:", error);
        throw error;  // Re-throw the error to handle it in the calling function if needed
    }
};

export { fetchDataEphys };
