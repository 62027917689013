import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import '../styles/main.css';
import { fetchDataEphys } from '../utils/fetchEphys';
import Plot from 'react-plotly.js';

const EphysPage = () => {
    const [plotData, setPlotData] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const handleFetchData = async () => {
        console.log('Fetching data started...');
        try {
            const ephysData = await fetchDataEphys();
            setPlotData(ephysData);
            setIsDataLoaded(true);
            console.log('Fetching data finished.');
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (isDataLoaded && plotData) {
            plotGraph(plotData);
            electrodeMap(plotData);
        }
    }, [isDataLoaded, plotData]);

    const plotGraph = (data) => {
        const spike_times = data.data.train;
        let traces = [];
        let y = 0;

        spike_times.forEach((vv) => {
            traces.push({
                x: vv,
                y: Array(vv.length).fill(y),
                mode: 'markers',
                marker: {
                    symbol: 'line-ns-open',
                    size: 3,
                    color: 'black',
                    opacity: 0.7
                },
                showlegend: false,
                type: 'scattergl'
            });
            y += 1;
        });

        return (
            <Plot
                data={traces}
                layout={{
                    title: 'Raster Plot',
                    xaxis: {
                        title: 'Time'
                    },
                    yaxis: {
                        title: 'Neuron'
                    }
                }}
                useResizeHandler
                style={{ width: '100%', height: '100%' }}
            />
        );
    };

    const electrodeMap = (data) => {
        // Convert metadata values to a config array
        const config = Object.values(data.data.metadata);
        console.log("Metadata first element:", config);
        //const neuron_dict = data.neuron_data[0]
    
        // Generate cluster numbers starting from 1
        //const clusterNum = Object.keys(data.data.neuron_dict).map(key => parseInt(key) + 1);
    
        // // Extract firing rates (assuming rates is a method that returns an array)
        // const fireRate = data.ephys_data.rates('Hz');
    
        // // Extract channel positions
        // const chnPos = Object.keys(data.neuron_dict).map(key => data.neuron_dict[key].position);
    
        // // Create the channel map
        // const chnMap = {
        //     cluster_number: clusterNum,
        //     pos_x: chnPos.map(pos => pos[0]),
        //     pos_y: chnPos.map(pos => pos[1]),
        //     fire_rate: fireRate
        // };
    
        // // Convert chnMap to a format suitable for plotting (e.g., an array of objects)
        // const chnMapArray = clusterNum.map((_, index) => ({
        //     cluster_number: chnMap.cluster_number[index],
        //     pos_x: chnMap.pos_x[index],
        //     pos_y: chnMap.pos_y[index],
        //     fire_rate: chnMap.fire_rate[index]
        // }));
    
        // // Your plotting code here, e.g., using a library like D3.js or Chart.js
        // console.log(chnMapArray);
    };

    return (
        <Layout title='Initialization' content='Initialization'>
            <div className='txt-body'>
                <h1>Ephys Page</h1>
                <hr />
            </div>
            <button
                className='btn btn-secondary mt-2'
                onClick={handleFetchData}
            >
                Plot graphs
            </button>
            {isDataLoaded && (
                <div style={{ width: '100%', height: '500px' }}>
                    {plotGraph(plotData)}
                </div>
            )}
        </Layout>
    );
};

export default EphysPage;
