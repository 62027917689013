import { Link, NavLink } from 'react-router-dom';
import logo from './../max_gif.gif';

import '../styles/nav.css';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg" style={{backgroundColor: 'rgba(0, 0, 0, 1)'}}>
      <div className='container-fluid flex-container'>
        <Link className='navbar-brand' to='/' style={{ marginRight: '10px', imageRendering: 'webkit-optimize-contrast' }}>
          <img src={logo} alt='Maxwell' width='80' />
        </Link>
        <div id='navbarNav'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <NavLink className='nav-title' activeClassName='active' to='/'>
                INITIALIZE
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-title' activeClassName='active' to='/control'>
                CONTROL
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-title' activeClassName='active' to='/view'>
                VIEW
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink className='nav-title' activeClassName='active' to='/ephys'>
                EPHYS
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
