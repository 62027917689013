const backendURL = process.env.REACT_APP_BACKEND_URL;

const fetchPicture = (uuid, chipId, device) => {
    let url = `${backendURL}/control/rioCam/picture`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchPicture};

const fetchSchedule = (uuid, device, chipId, action, days, hours, minutes, seconds, flag) => {
    let url = `${backendURL}/control/rioCam/schedule`;

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            action,
            device,
            days, 
            hours, 
            minutes, 
            seconds,
            flag
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    })
    .then((data) => {
        console.log(data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};

export {fetchSchedule};

const fetchLoadImages = async (uuid, chipId, device) => {
    let url = `${backendURL}/control/rio_cam/load_images`;
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            uuid,
            chipId,
            device,
        }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        // Handling the file response here
        return response.blob();  // Use blob() instead of json() for file download
    })
    .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // Suggest a filename to save as
        a.download = 'downloaded_images.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        alert('Download has started!');
    })
    .catch((error) => {
        console.error('Error:', error);
    });
};
	
	// if (index !== -1) {
	// 	// Grab the corresponding image name using the index
	// 	const imageName = data.image_name[index];
	// 	console.log(`Image name for device ${deviceId} at timestamp ${uuid}: ${imageName}`);
	// 	try {
	// 		const response = await fetch(`${backendURL}/visualization-rio-cam/getImage?device_id=${deviceId}&uuid=${uuid}&image_name=${imageName}`);
	// 		//const response = await fetch(`http://mxvisuals-backend.braingeneers.gi.ucsc.edu/visualization/getImage?device_id=${deviceId}&uuid=${uuid}&image_name=${imageName}`);
			
	// 		if (!response.ok) {
	// 			throw new Error(`HTTP error! status: ${response.status}`);
	// 		}
			
	// 		const blob = await response.blob();
	// 		const url = URL.createObjectURL(blob);
	// 		//setImage(url);
	// 		const newWindow = window.open("", "_blank");

	// 		if (newWindow) {
	// 			newWindow.document.title = imageName;
	// 			newWindow.document.body.innerHTML = `
	// 				<a href="${url}" download="${imageName}">
	// 					<img id="${imageName}" src="${url}" alt="${imageName}" />
	// 				</a>`;
	// 		}

	// 	} catch (error) {
	// 		console.error('Fetch error:', error);
	// 	}
	// }

export {fetchLoadImages};