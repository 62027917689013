import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
import { fetchConfig, fetchRequest } from '../../utils/fetchZambezi';
import { ScheduleModal } from '../Zambezi/ZambeziSchedule';
import { DefaultCmds, ConfigScheduleLayout} from '../Devices';

const Zambezi = ({deviceName, displayName, state}) => {	
	const [showModal, setShowModal] = useState(false);

	const device = deviceName;
	const seconds = ''

	const { uuid, setUUID } = useGlobalState();
	const [chipId, setChipId] = useState('');
	const [volume, setVolume] = useState('');
	
	// schedule related
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showScheduleConfigModal, setShowScheduleConfigModal] = useState(false);

	// related to pop-up window
	const [config, setConfig] = useState({
		index: 'right',
		media: 'Ry5',
		inPort: '1',
		outPort: '6',
		exhaustPort: '5',
		speed: '15',
		inVolUl: '',
		outVolUl: '',
		dispPort: '1',
		aspirPort: '1'
	});
	const [showConfigModal, setShowConfigModal] = useState(false);
	const handleConfigChange = (e) => {
		setConfig({
		  ...config,
		  [e.target.name]: e.target.value
		});
	};

	const handleConfigSubmit = async (e) => {
		e.preventDefault();
		try {
		  await fetchConfig(uuid, device, chipId, config);
		  setShowConfigModal(false);
		} catch (error) {
		  console.error('There was an error submitting the configuration:', error);
		}
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const defineAction = (action) =>{
		fetchRequest(uuid, device, chipId, volume, action);
	}

	return (
		<div style={{ width: '50%' }}>
			<div>
				<h1>{displayName}</h1>
			</div>
			<div className='m-2'>
				<label>chip id: </label>
				<input 
					className='m-2' 
					type="text"
					value={chipId}
					onChange={(e) => setChipId(e.target.value)} 
				/>
			</div>
			<div className='m-2'>
				<label>volume (uL): </label>
				<input 
					className='m-2' 
					type="text"
					value={volume}
					onChange={(e) => setVolume(e.target.value)} 
				/>
			</div>
            <DefaultCmds
                device = {device}
                SelectedTime = {seconds}
            />
			<div className='button-group'>
				<button className='btn btn-secondary mt-2' onClick={() => setShowScheduleModal(true)}>Schedule</button>
				<button className='btn btn-secondary mt-2' onClick={() => setShowScheduleConfigModal(true)}>Config Schedule</button>
			</div>
			<div className='button-group'>
					<button className='btn btn-secondary mt-2' onClick={() => setShowConfigModal(true)}>Config</button>

					<button className='btn btn-secondary mt-2' onClick={() => defineAction("dispense")}>Dispense</button>
					<button className='btn btn-secondary mt-2' onClick={() => defineAction("aspirate")}>Aspirate</button>
					<button className='btn btn-secondary mt-2' onClick={() => defineAction("feed")}>Feed</button>

					<button className='btn btn-secondary mt-2' >Pull</button>
					<button className='btn btn-secondary mt-2' >Plunge</button>
			</div>
			
            <ScheduleModal 
                showScheduleModal={showScheduleModal}
                setShowScheduleModal={setShowScheduleModal}
				device = {device}
				chipId = {chipId}
            />
			<ConfigScheduleLayout
			    showScheduleConfigModal={showScheduleConfigModal}
				setShowScheduleConfigModal={setShowScheduleConfigModal}
				device = {device}
				state = {state}
			></ConfigScheduleLayout>


			{showConfigModal && (
			<div className='modal-config'>
				<div className='modal-content-config'>
					<span className='close' onClick={() => setShowConfigModal(false)}>&times;</span>
					<form>
						<div>
							<label>Index:</label>
							<input name="index" value={config.index} onChange={handleConfigChange} />
						</div>
						<div>
							<label>Media:</label>
							<input name="media" value={config.media} onChange={handleConfigChange} />
						</div>
						<div>
							<label>IN Port:</label>
							<input name="inPort" value={config.inPort} onChange={handleConfigChange} />
						</div>
						<div>
							<label>OUT Port:</label>
							<input name="outPort" value={config.outPort} onChange={handleConfigChange} />
						</div>
						<div>
							<label>EXHAUST Port:</label>
							<input name="exhaustPort" value={config.exhaustPort} onChange={handleConfigChange} />
						</div>
						<div>
							<label>Speed:</label>
							<input name="speed" type="number" value={config.speed} onChange={handleConfigChange} />
						</div>
						<div>
							<label>IN Vol (uL):</label>
							<input name="inVolUl" type="number" value={config.inVolUl} onChange={handleConfigChange} />
						</div>
						<div>
							<label>OUT Vol (uL):</label>
							<input name="outVolUl" type="number" value={config.outVolUl} onChange={handleConfigChange} />
						</div>
						<div>
							<label>DISP Port:</label>
							<input name="dispPort" type="number" value={config.dispPort} onChange={handleConfigChange} />
						</div>
						<div>
							<label>ASPIR Port:</label>
							<input name="aspirPort" type="number" value={config.aspirPort} onChange={handleConfigChange} />
						</div>
						<div>
							<button className="btn btn-secondary mt-2" 
								onClick={() => setShowConfigModal(false)}>
								Close
							</button>
							<button 
							className='btn btn-secondary mt-2'
							onClick={handleConfigSubmit}>
							Go
							</button>
						</div>
					</form>
				</div>
			</div>
      		)}
    	</div>
	);
};

export {Zambezi}