import React, { useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import {fetchState} from '../../utils/fetchDeviceState'
//mspm fetchRioCam
import { fetchSchedule } from '../../utils/fetchRioCam';
import {ScheduleLayout} from '../Devices'

const ScheduleModal = ({ showScheduleModal, setShowScheduleModal, device, chipId }) => {
    const [days, setDays] = useState('0');
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');
    const [seconds, setSeconds] = useState('0');
    const [flag, setFlag] = useState(false);

    const { uuid } = useGlobalState();
  
    const action = "picture";
  
    const handleSubmit = () => {
      fetchSchedule(uuid, device, chipId, action, days, hours, minutes, seconds, flag)
      setShowScheduleModal(false);
    };

    const handleClose = () => setShowScheduleModal(false);
  
    return (
      <div>
        {showScheduleModal && (
          <div className="modal">
            <div className="modal-content">
              <h2>Take a picture every:</h2>
              <ScheduleLayout
                            days={days} setDays={setDays}
                            hours={hours} setHours={setHours}
                            minutes={minutes} setMinutes={setMinutes}
                            seconds={seconds} setSeconds={setSeconds}
                            flag={flag} setFlag={setFlag}
                            handleSubmit={handleSubmit}
                            handleClose={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export { ScheduleModal };