import React, { useState } from 'react';

// Rows definition is shared among all components using CheckBoxGrid
export const rows = [
  ["?? ", "?1 ", "?2 ", "?3 ", "?4 ", "?5 ", "?6 "],
  ["A? ", "A1 ", "A2 ", "A3 ", "A4 ", "A5 ", "A6 "],
  ["B? ", "B1 ", "B2 ", "B3 ", "B4 ", "B5 ", "B6 "],
  ["C? ", "C1 ", "C2 ", "C3 ", "C4 ", "C5 ", "C6 "],
  ["D? ", "D1 ", "D2 ", "D3 ", "D4 ", "D5 ", "D6 "]
];

export function CheckBoxGrid({ selectedValues, onCheckboxChange, clearAllCheckboxes }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '350px' }}>
        {rows.map((row, rowIndex) => (
          <div key={rowIndex} style={{ display: 'flex' }}>
            {row.map((label, columnIndex) => (
              <label key={label.trim()} style={{ width: '50px' }}>
                {label}
                <input
                  type="checkbox"
                  name={label.trim()}
                  checked={selectedValues.includes(label.trim())}
                  onChange={(e) => onCheckboxChange(e, rowIndex, columnIndex)}
                />
              </label>
            ))}
          </div>
        ))}
        <button className='btn btn-secondary mt-2'
          onClick={clearAllCheckboxes}
          style={{ alignSelf: 'center', marginTop: '10px' }}
        >Clear All</button>
      </div>
    </div>
  );
}

// Generic function for handling checkbox changes
export function handleCheckboxChange(e, rowIndex, columnIndex, selectedCheckboxes, setSelectedCheckboxes) {
    const value = e.target.name;
  
    if (rowIndex === 0 && columnIndex === 0) {
      if (selectedCheckboxes.includes(value)) {
        setSelectedCheckboxes([]);
      } else {
        const allCheckboxes = rows.flat().map(label => label.trim());
        setSelectedCheckboxes(allCheckboxes);
      }
    } else if (rowIndex === 0 && columnIndex > 0) {
      const columnValues = rows.map(row => row[columnIndex].trim());
      setSelectedCheckboxes(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => !columnValues.includes(item));
        } else {
          return [...new Set([...prev, ...columnValues])];
        }
      });
    } else if (columnIndex === 0 && rowIndex > 0) {
      const rowValues = rows[rowIndex].map(label => label.trim());
      setSelectedCheckboxes(prev => {
        if (prev.includes(value)) {
          return prev.filter(item => !rowValues.includes(item));
        } else {
          return [...new Set([...prev, ...rowValues])];
        }
      });
    } else {
      const newSelectedCheckboxes = selectedCheckboxes.includes(value) ? selectedCheckboxes.filter(item => item !== value) : [...selectedCheckboxes, value];
      setSelectedCheckboxes(newSelectedCheckboxes);
      manageGroupCheckboxState(rowIndex, columnIndex, newSelectedCheckboxes, setSelectedCheckboxes);
    }
  }

  	// Helper function to manage both row and column group checkbox state
function manageGroupCheckboxState(rowIndex, columnIndex, selectedCheckboxes) {
    // Manage row group checkbox
    const rowLabel = rows[rowIndex][0].trim();
    const rowValues = rows[rowIndex].slice(1).map(label => label.trim());
    const allRowSelected = rowValues.every(label => selectedCheckboxes.includes(label));
    if (!allRowSelected && selectedCheckboxes.includes(rowLabel)) {
        selectedCheckboxes.splice(selectedCheckboxes.indexOf(rowLabel), 1);
    }

    // Manage column group checkbox
    const columnLabel = rows[0][columnIndex].trim();
    const columnValues = rows.map(row => row[columnIndex].trim());
    const allColumnSelected = columnValues.every(label => selectedCheckboxes.includes(label));
    if (!allColumnSelected && selectedCheckboxes.includes(columnLabel)) {
        selectedCheckboxes.splice(selectedCheckboxes.indexOf(columnLabel), 1);
    }
}


// Related to Rio camera
export const rowsCam = [
    ["A1 ", "A2 ", "A3 ", "A4 ", "A5 ", "A6 "],
    ["B1 ", "B2 ", "B3 ", "B4 ", "B5 ", "B6 "],
    ["C1 ", "C2 ", "C3 ", "C4 ", "C5 ", "C6 "],
    ["D1 ", "D2 ", "D3 ", "D4 ", "D5 ", "D6 "]
];
  
export function CheckBoxGridCam({ selectedValue, onCheckboxChange }) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '350px' }}>
          {rowsCam.map((row, rowIndex) => (
            <div key={rowIndex} style={{ display: 'flex' }}>
              {row.map((label, columnIndex) => (
                <label key={label.trim()} style={{ width: '50px' }}>
                  {label}
                  <input
                    type="checkbox"
                    name={label.trim()}
                    checked={selectedValue.includes(label.trim())}
                    onChange={(e) => onCheckboxChange(e, rowIndex, columnIndex)}
                  />
                </label>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

export function handleCheckboxChangeCam(e, rowIndex, columnIndex, setSelectedCheckboxes) {
    const value = e.target.name;

    // Set the selected checkboxes to only the newly checked checkbox
    setSelectedCheckboxes([value]); // This ensures only the clicked checkbox is selected
}