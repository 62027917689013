import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';

import { Dorothy } from '../components/Dorothy/Dorothy';
import { Zambezi } from '../components/Zambezi/Zambezi';
import { Dinolites } from '../components/Dinolites/Dinolites';
import { ZambeziCam } from '../components/ZambeziCam/ZambeziCam';
import { RioCam } from '../components/RioCam/RioCam';
import { Nile } from '../components/Nile/Nile';

import { DinolitesStart, DorothyStart, ZambeziStart, ZambeziCamStart, RioCamStart, NileStart } from '../components/DevicesState';
import '../styles/main.css';
import { useGlobalState } from '../contexts/GlobalStateContext';


// Biomed integrated system -> (device, type) -> (dorothy-cam, microscope), (dorothy, maxwell), (zambezi, autoculture), (zambezi-cam, camera)

// WRP Microscope ->  (device, type)-> (carol-cam, microscope)

// microscope type, ex: Dinolites
// maxwell type, ex: Dorothy
// autoculture type, ex: Zambezi


const ControlPage = () => {

const backendURL = process.env.REACT_APP_BACKEND_URL;

	const [seconds, setSeconds] = useState('');
	const [action, setAction] = useState('');
	const { uuid, setUUID } = useGlobalState();
	
	const [cmd, setCmd] = useState('');
	const [isStarted, setIsStarted] = useState(false);
	const [isPaused, setIsPaused] = useState(false);
	const [selectedDevices, setSelectedDevices] = useState([]);
	const toggleDeviceExpansion = (device) => {
		if (selectedDevices.includes(device)) {
		  setSelectedDevices(prevDevices => prevDevices.filter(d => d !== device));
		} else {
		  setSelectedDevices(prevDevices => [...prevDevices, device]);
		}
	  };

	const devices = ['Zambezi Pumps', 'Zambezi Camera', 'Dorothy', 'Dorothy Camera', 'Ada MaxOne', 'Ada Camera', 'Barbara', 'Barbara Camera', 'Rio Camera', 'Nile Pumps'];
	const [showSuggestions, setShowSuggestions] = useState(false);

	const [deviceStates, setDeviceStates] = useState({});

		useEffect(() => {
			let url = `${backendURL}/control/showStates`;

			const evtSource = new EventSource(url);
	
			evtSource.onmessage = function(event) {
				const newDeviceStates = JSON.parse(event.data);
				console.log(deviceStates);
				setDeviceStates(newDeviceStates);
			};
			
			evtSource.onerror = function(error) {
				console.error('EventSource failed:', error);
				evtSource.close();
			};
	
			return () => {
				evtSource.close();
			};
		}, [backendURL]);

		useEffect(() => {
			let url = `${backendURL}/control/updateSelectedDevices`;
			const updateDevicesOnServer = async () => {
				try {
					const response = await fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							selectedDevices: selectedDevices
						})
					});
	
					const data = await response.json();
					console.log('Success:', data);
				} catch (error) {
					console.error('Error updating devices:', error);
				}
			};

			updateDevicesOnServer();
	
		}, [selectedDevices]);
	
	const toggleDeviceSelection = (device) => {
		if (selectedDevices.includes(device)) {
		  setSelectedDevices(selectedDevices.filter(d => d !== device));
		} else {
		  setSelectedDevices([...selectedDevices, device]);
		}
	  };

	  const handleInputChange = (e) => {
		setCmd(e.target.value);
		setShowSuggestions(false); // show suggestions when user types
	  };
	
	  const handleSuggestionClick = (example) => {
		console.log('Suggestion clicked:', example); // Debugging log
		setCmd(example); // Set the input value to the clicked example command
		setShowSuggestions(false); // Hide suggestions after user selects an example
	  };

	const handleButtonClick = () => {
		let url = `${backendURL}/control/sendAction`;

		fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				action,
				selectedDevices,
				seconds,
				uuid,
			}),
		})
		.then((response) => response.json())
		.then((data) => {
			console.log('Success:', data);
			if (action === 'start' || action === 'end') {
				setIsStarted(!isStarted);
			} else if (action === 'pause' || action === 'resume') {
				setIsPaused(!isPaused);
			}
		})
		.catch((error) => {
			console.error('Error:', error);
		});
	};

	return (
		<Layout title='Control' content='Control'>
			<div className='txt-body'>
				<h1>Control Page</h1>
				<hr />
					<input
						type='uuid'
						className="form-control form-control-wide"
						value={uuid}
						onChange={(e) => setUUID(e.target.value)}
						placeholder='Enter uuid'
					/>
			</div>
			<div className='txt-body'> 
				<hr />
				{devices.map((device, index) => (
				<div key={index}>
					<div className="device-toggle" onClick={() => toggleDeviceExpansion(device)}>
					<span>{selectedDevices.includes(device) ? "v" : ">"}</span>
					<label className="device-toggle-label">{device}</label>
					</div>

					{selectedDevices.includes(device) && (
					<div className="device-grid">
						{device === 'Zambezi Pumps' && 
						<div className="device-item">
							<Zambezi deviceName = 'zambezi' displayName = 'Zambezi Pumps' state={deviceStates['zambezi']}/>
							<ZambeziStart deviceName = 'zambezi' state={deviceStates['zambezi']} />
						</div>
						}
						
						{device === 'Zambezi Camera' && 
						<div className="device-item">
							<ZambeziCam deviceName = 'zambezi-cam' displayName = 'Zambezi Camera' state={deviceStates['zambezi-cam']}/>
							<ZambeziCamStart deviceName = 'zambezi-cam' state={deviceStates['zambezi-cam']} />
						</div>
						}

						{device === 'Dorothy' && 
						<div className="device-item">
							<Dorothy deviceName = 'dorothy' displayName = 'Dorothy MaxOne' state={deviceStates['dorothy']}/>
							<DorothyStart deviceName = 'dorothy' state={deviceStates['dorothy']}/>
						</div>
						}

						{device === 'Dorothy Camera' && 
						<div className="device-item">
							<Dinolites deviceName = 'dorothy-cam' displayName = 'Dorothy Camera' state={deviceStates['dorothy-cam']}/>
							<DinolitesStart deviceName = 'dorothy-cam' state={deviceStates['dorothy-cam']} />
						</div>
						}

						{device === 'Barbara' && 
						<div className="device-item">
							<Dorothy deviceName = 'barbara' displayName = 'Barbara MaxOne' state={deviceStates['barbara']}/>
							<DorothyStart deviceName = 'barbara' state={deviceStates['barbara']}/>
						</div>
						}

						{device === 'Barbara Camera' && 
						<div className="device-item">
							<Dinolites deviceName = 'barbara-cam' displayName = 'Barbara Camera'state={deviceStates['barbara-cam']}/>
							<DinolitesStart deviceName = 'barbara-cam' state={deviceStates['barbara-cam']}/>
						</div>
						}

						{device === 'Ada MaxOne' && 
						<div className="device-item">
							<Dorothy deviceName = 'ada' displayName = 'Ada MaxOne' state={deviceStates['ada']}/>
							<DorothyStart deviceName = 'ada' state={deviceStates['ada']}/>
						</div>
						}

						{device === 'Rio Camera' && 
						<div className="device-item">
							<RioCam deviceName = 'rio-cam' displayName = 'Rio Camera' state={deviceStates['rio-cam']}/>
							<RioCamStart deviceName = 'rio-cam' state={deviceStates['rio-cam']}/>
						</div>
						}

						{device === 'Ada Camera' && 
						<div className="device-item">
							<Dinolites deviceName = 'ada-cam' displayName = 'Ada Camera' state={deviceStates['ada-cam']}/>
							<DinolitesStart deviceName = 'ada-cam' state={deviceStates['ada-cam']}/>
						</div>
						}

						{device === 'Nile Pumps' && 
						<div className="device-item">
							<Nile deviceName = 'nile' displayName = 'Nile Pumps' state={deviceStates['nile']}/>
							<NileStart deviceName = 'nile' state={deviceStates['nile']}/>
						</div>
						}

					</div>
					)}
				</div>
				))}
			</div>
		</Layout>
	);
};

export default ControlPage;
